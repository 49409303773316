import { GetObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiError } from "src/services/ApiService"
import { DishDeleteAction, DishDeleteStoreFields } from "./dishDelete"
import { DishGetAction, DishGetStoreFields } from "./dishGet"
import { DishPostAction, DishPostStoreFields } from "./dishPost"
import { DishPutAction, DishPutStoreFields } from "./dishPut"
import { DishRecipePutAction } from "./dishRecipePut"
import { DishIngredientDeleteStoreFields, DishIngredientPostStoreFields, DishIngredientPutStoreFields, DishIngredientUpdatableDTO, DishVariantDeleteStoreFields, DishVariantPostStoreFields, DishVariantPutStoreFields, DishVariantUpdatableDTO, IngredientsActionTypes } from "./IngredientsTypes"
import { DishMultiplierSetStoreFields, DishSetMultiplierAction } from "./dishMultiplierSet"



export interface FoodMacrosDTO {
    ntrEnergy: number,
    ntrPrtn: number,
    ntrFat: number,
    ntrCrbhdrt: number,
    ntrFiber: number,
    ntrSodium: number,
    ntrNkt: number
}

export interface DishIngredientDTO extends DishIngredientUpdatableDTO {
    id: number,
    dishVariantId: number
    macros?: FoodMacrosDTO
}

export interface DishVariantDTO extends DishVariantUpdatableDTO {
    dishId: number
    id: number
    ingredients: DishIngredientDTO[]
}


export interface DishUploadDTO {
    name: string,
    description?: string,
    isBreakfast: boolean,
    isScndBreakfast: boolean,
    isLunch: boolean,
    isTea: boolean,
    isSupper: boolean,
    url?: string,
    servings: number,
    servingsUnitId: number,
    formFile?: File
    tags: number[]
}

export interface DishRecipeDTO extends DishUploadDTO {
    id: number,
    pictureOriginalPath: string,
    pictureCrop35number25numberPath: string,
    userId: number,
    dishVariants: DishVariantDTO[],
    recipeRichText: string,
    canEdit: boolean, // readonly
    ntrEnergy: number,
    ntrPrtn: number,
    ntrFat: number,
    ntrCrbhdrt: number,
}


/// CLEAR_FOOD_DISH

export const CLEAR_FOOD_DISH = 'CLEAR_FOOD_DISH'

export interface ClearFoodDishAction {
    type: typeof CLEAR_FOOD_DISH
}

// STORE

export interface FoodDishStoreState {
    dish_get?: DishGetStoreFields
    dish_put?: DishPutStoreFields
    dish_post?: DishPostStoreFields
    dish_delete?: DishDeleteStoreFields
    dish_recipe_put?: DishGetStoreFields
    dish_variant_post?: DishVariantPostStoreFields
    dish_variant_put?: DishVariantPutStoreFields
    dish_variant_delete?: DishVariantDeleteStoreFields
    dish_ingredient_post?: DishIngredientPostStoreFields
    dish_ingredient_put?: DishIngredientPutStoreFields
    dish_ingredient_delete?: DishIngredientDeleteStoreFields    
    dish_multiplier_set?: DishMultiplierSetStoreFields    
}

export const foodDishInitialState: FoodDishStoreState = {}


export type FoodDishActionTypes = DishGetAction | DishPostAction | DishDeleteAction
    | DishRecipePutAction | IngredientsActionTypes | ClearFoodDishAction | DishPutAction
    | DishSetMultiplierAction