import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BankingStoreState, BankingThunk } from "../../types"



export const UTILITIES_LOGIN_POST = 'UTILITIES_LOGIN_POST'

export interface UtilitiesLoginPostParams {
    bankId: number

}

export const utilitiesLoginPost = (params: UtilitiesLoginPostParams): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/utilities/login' 
        path = concatApiUrl(path, params.bankId, "bankId")

        return callPostDispatchedApiSameName<string, BankingStoreState, any>(
            apiService, path, dispatch, UTILITIES_LOGIN_POST, {});

    }
}

export interface UtilitiesLoginPostStoreFields extends PostObjectStoreFields<string> { }

export interface UtilitiesLoginPostAction extends UtilitiesLoginPostStoreFields {
    type: typeof UTILITIES_LOGIN_POST
}