import { UtilitiesEtlPostStoreFields, UtilitiesEtlPostAction } from "./utilitiesEtlPost"
import { clearPartialSameNameGeneric } from "src/redux/actions/clearPartialGeneric"
import { BankingThunk, BankingStoreState } from "../../types"
import { UtilitiesImportEtlPostStoreFields, UtilitiesImportEtlPostAction } from "./utilitiesImportEtlPost"
import { UtilitiesLoginPostAction, UtilitiesLoginPostStoreFields } from "./utilitiesLoginPost"



/// CLEAR_BANKING_ETL

export const CLEAR_BANKING_ETL = 'CLEAR_BANKING_ETL'

export interface ClearFoodProductAction {
    type: typeof CLEAR_BANKING_ETL
}

export const clearBankingETLPartial = (part: string): BankingThunk => {
    return (dispatch) => {
        clearPartialSameNameGeneric<BankingStoreState>(part, CLEAR_BANKING_ETL, dispatch);
    }
}

/// STORE

export interface ETLStoreState {
    utilities_etl_post?: UtilitiesEtlPostStoreFields
    utilities_login_post?: UtilitiesLoginPostStoreFields
    utilities_import_etl_post?: UtilitiesImportEtlPostStoreFields
}


export type ETLActionTypes = UtilitiesEtlPostAction | UtilitiesImportEtlPostAction | UtilitiesLoginPostAction

