import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFields, GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApi, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk, FoodThunkDispatch } from "../../types"
import { DishRecipeDTO } from "./types"

/// DISH_GET

export const DISH_GET = 'DISH_GET'

export interface DishGetParams extends ApiCallBaseData {
    id: number
    extendWithIngredientsAndMacros?: boolean
}

export const dishGet = (params: DishGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/dishes/' + params.id + '?extendWithIngredients=true'
        path = concatApiUrl(path, params.extendWithIngredientsAndMacros, "extendWithIngredientsAndMacros", false);

        return callGetDispatchedApiSameName<DishRecipeDTO, FoodStoreState, void, DishGetParams>(
            apiService, path, dispatch, DISH_GET, undefined, params);

    }
}

export interface DishGetStoreFields extends GetObjectStoreFieldsWithParams<DishRecipeDTO, DishGetParams> { }

export interface DishGetAction extends DishGetStoreFields {
    type: typeof DISH_GET
}